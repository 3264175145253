import { createGlobalStyle, css } from 'styled-components'

const sizes = {
  desktop: 768, // normally 992,
  mobile: 576
}

// Iterate through the sizes and create a media template
const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${sizes[label] / 16}em) {
      ${css(...args)}
    }
  `
  return acc
}, {})

export const theme = {
  primary: '#FF851B',
  primaryText: 'white',
  primaryBtnHover: '#9e4b04',
  secondary: '#9e4b04',
  secondaryTest: '',
  bodyBgColor: '#7B290A',
  successBg: '#2C6700',
  success: '#ffff',
  warningBg: '#FFCC00',
  warning: '#333',
  media
}

// const formBorderColor = '#e1e1e1'

export const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    height: 100%;
    font-family: montserrat, sans-serif;
    font-weight: 400;
    font-style: normal;
    color: #D4C4AD;
    font-display: swap;
    background-color: ${theme.bodyBgColor};
    
  }
  a {
    color: #084c8d;
    text-decoration: none;
    :hover {
      color: #6682b5;
    }
  }

  .highlight {
    color: #FF851B;
    font-weight: 700;
  }

  ::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color:    #aaa;
  }
  :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color:    #aaa;
    opacity:  1;
  }
  ::-moz-placeholder { /* Mozilla Firefox 19+ */
    color:    #aaa;
    opacity:  1;
  }
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color:    #aaa;
  }
  ::-ms-input-placeholder { /* Microsoft Edge */
    color:    #aaa;
  }
`
