import styled from 'styled-components'
import Image from 'next/image'
import Layout from '../components/layout'
import Input from '../components/input'

const Index = () => {
  return <Layout
    title={`Bird's Bar`}
    description='Good times, great food, and delicious drinks.'
    keywords='bar cafe food restaurant'>
      <div style={{ textAlign: 'center' }}><Image src='/logo.png' width={500} height={500}  /></div>
      <div style={{ maxWidth: '500px', marginRight: 'auto', marginLeft: 'auto', textAlign: 'center', paddingLeft: '10px', paddingRight: '10px' }}>
        <div style={{ overflow: 'hidden', borderRadius: '30px' }}>
          <Image src='/cake1.jpg' width={500} height={481} alt='Cake'  />
        </div>
      </div>
      <div style={{ maxWidth: '500px', marginRight: 'auto', marginLeft: 'auto', textAlign: 'center', marginTop: '30px', marginBottom: '40px' }}>
        Ridgefield, Connecticut
      </div>
  </Layout>
}

export default Index
