import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Container = styled.div`
  margin-bottom: 20px;
`

const Label = styled.label`
  display: block;  
  margin-bottom: 3px;
`

const LabelError = styled.div`
  float: right;
  width: auto;
  color: red;
  font-size: 14px;
  padding-top: 2px;
`

const TextArea = styled.textarea`
  color: #444;
  height: 175px;
  width: 100%;
  resize: vertical;
  border-radius: 2px;
  border: solid 1px #ddd;
  font-size: 15px;
  font-weight: 400;
  padding: 10px;
  box-sizing: border-box;
  -webkit-box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  &:focus {
    box-shadow: none;
    background-color: #f8fdfe;
    -moz-box-shadow: none;
    box-shadow: none !important;
    outline: none !important;
  }
  ${({ error }) => error && `
    border: solid 1px red;
    background-color: #fcebec;
    &:focus {
      background-color: #fcebec;
    }
  `}
`

const Input = styled.input`
  color: #444;
  height: 30px;
  width: 100%;
  border-radius: 2px;
  border: solid 1px #ddd;
  font-size: 15px;
  font-weight: 400;
  padding-left: 5px;
  padding-right: 5px;
  box-sizing: border-box;
  -webkit-box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  &:focus {
    box-shadow: none;
    background-color: #f8fdfe;
    -moz-box-shadow: none;
    box-shadow: none !important;
    outline: none !important;
  }
  ${({ error }) => error && `
    border: solid 1px red;
    background-color: #fcebec;
    &:focus {
      background-color: #fcebec;
    }
  `}
`

class InputComponent extends React.Component {
  render () {
    return (
      <Container>
        <Label>
          {this.props.label}
          {(this.props.error) && <LabelError>{this.props.error}</LabelError>}
        </Label>
        {this.props.type === 'textarea' && <TextArea
          error={this.props.error}
          name={this.props.name}
          required={this.props.required}
          placeholder={this.props.placeholder}
          onChange={this.props.onChange}
        >{this.props.value}</TextArea>}
        {this.props.type !== 'textarea' && <Input
          type={this.props.type || 'text'}
          error={this.props.error}
          name={this.props.name}
          maxLength={this.props.maxLength}
          required={this.props.required}
          value={this.props.value}
          placeholder={this.props.placeholder}
          onChange={this.props.onChange} />}
      </Container>
    )
  }
}

InputComponent.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  error: PropTypes.string
}

export default InputComponent
