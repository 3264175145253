import Head from 'next/head'
import { ThemeProvider } from 'styled-components'
import { theme, GlobalStyle } from '../src/theme'

const Layout = ({ title, description, keywords, children }) => {
  return <ThemeProvider theme={theme}>
    <>
      <Head>
        <title>{title || `Bird's Bar`}</title>
        <meta charSet='utf-8' />
        <meta name='viewport' content='initial-scale=1.0, width=device-width' />

        <link href='/favicon.ico' rel='icon' type='image/x-ico' />
        <link rel='shortcut icon' href='/favicon.ico' type='image/x-icon' />
        <link href='/favicon.ico' rel='apple-touch-icon-precomposed' />

        <meta httpEquiv='content-type' content='text/html; charset=utf8' />
        <meta name='description' content={description} />
        <meta name='keywords' content={keywords} />
        <meta name='author' content='Bird' />
        <meta name='robots' content='index, follow' />
        <meta name='revisit-after' content='1 Week' />
        <meta name='language' content='EN' />
        <meta name='copyright' content='2022' />

        <link rel="stylesheet" href="https://use.typekit.net/mnf5jlj.css"></link>

      </Head>
      { children }
      <GlobalStyle />
    </>
  </ThemeProvider>
}

export default Layout
